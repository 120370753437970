import React from 'react';
import styled from 'styled-components';

import Section from '@latitude/section';
import { Link } from '@latitude/link';
import SvgInline from '@latitude/svg-inline';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import { SectionSubtitle, SectionTitle } from './_sectionHeading';

import imgConfettiLeft from './images/merchant-confetti-left.svg';
import imgConfettiRight from './images/merchant-confetti-right.svg';

function AreYouAMerchantSection({ id }) {
  return (
    <AreYouAMerchantContainer id={id}>
      <SectionTitle>Become a Latitude partner</SectionTitle>
      <SectionSubtitle>
        Give your customers more time to pay with long term Interest Free
        payment plans available through participating Latitude credit cards.
      </SectionSubtitle>

      <p>
        <br />
        <Link
          button={BUTTON_STYLE.SECONDARY_INVERSE}
          href="/interest-free/business/"
          trackId="callout-visit-our-merchant-hub"
          trackEventData={{ location: 'Are you a merchant' }}
        >
          Visit our Merchant Hub
          <SvgInline name="external-link" />
        </Link>
      </p>
    </AreYouAMerchantContainer>
  );
}

const AreYouAMerchantContainer = styled(Section)`
  background-color: #0146aa;
  background-image: linear-gradient(128.6deg, #0146aa 0%, #0162ed 67.76%);
  @media only screen and (min-width: 768px) {
    background: url(${imgConfettiLeft}) no-repeat left top,
      url(${imgConfettiRight}) no-repeat right top,
      linear-gradient(128.6deg, #0146aa 0%, #0162ed 67.76%);
    background-size: auto 100%, auto, auto 100%;
  }
  @media only screen and (max-width: 768px) {
  }

  padding: 32px 0;

  text-align: center;

  h3,
  p {
    color: #ffffff;
  }
  a {
    width: fit-content;
    margin: 0 auto 0 auto;
  }
  a svg {
    height: 18px;
    width: auto;
    margin-left: 5pt;
  }
`;

export default AreYouAMerchantSection;
