import React, { useContext, useEffect } from 'react';
import Helmet from 'react-helmet';

import Layout from '../../components/layout';

import CarouselHeader from '@/components/Carousel/carouselHeader';
import AreYouAMerchantSection from './_areYouAMerchant';
import HereToHelpSection from './_hereToHelp';
import FindAStoreSection from './_findAStore';
import PAGE_HEADER_ITEMS from './data/interest-free-header.json';
import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { useSetState } from '@/utils/hooks';
import '@/components/PageLayout/pageHeader.css';

function FindAStorePage(props) {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={props.location}>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.latitudefinancial.com.au/interest-free/find-a-store/"
        />
        <title>Latitude Interest Free | Find a Store</title>
        <meta name="description" content="Find an Interest-Free retailer." />
      </Helmet>

      <main className="navigation-spacer" css="background-color: #f8f8f8;">
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <CarouselHeader HEADER_ITEMS={PAGE_HEADER_ITEMS} />
        )}
        <FindAStoreSection id="find-a-store" />
        <AreYouAMerchantSection id="become-a-merchant" />
        <HereToHelpSection id="here-to-help" />
      </main>
    </Layout>
  );
}

export default FindAStorePage;
