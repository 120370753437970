import React from 'react';
import styled from 'styled-components';

import Section from '@latitude/section';
import { Link } from '@latitude/link';
import SvgInline from '@latitude/svg-inline';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { SectionSubtitle, SectionTitle } from './_sectionHeading';

const HEADING = "We're here to help";

const callToAction = (
  <p>
    <br />
    <Link
      button={BUTTON_STYLE.TERTIARY}
      href="https://latitudefs.zendesk.com/hc/en-au"
      trackId="callout-visit-our-help-centre"
      trackEventData={{ location: HEADING }}
    >
      Visit our Help Centre
      <SvgInline name="external-link" />
    </Link>
  </p>
);

function HereToHelpSection({ id }) {
  return (
    <AnalyticsLocationProvider location={HEADING}>
      <HereToHelpContainer id={id}>
        <SectionTitle>{HEADING}</SectionTitle>
        <SectionSubtitle>
          For more information and useful help request forms.
        </SectionSubtitle>
        {callToAction}
      </HereToHelpContainer>
    </AnalyticsLocationProvider>
  );
}

const HereToHelpContainer = styled(Section)`
  background-color: #e2f2ff;
  text-align: center;
  padding: 32px 0;

  p a {
    width: fit-content;
    margin: 0 auto 0 auto;
    border: 1px solid #0061ee !important;
  }
  p a svg {
    height: 18px;
    width: auto;
    margin-left: 5pt;
  }
`;

export default HereToHelpSection;
